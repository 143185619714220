import { eventTypes, constants, clartityTags } from "lib/utils/constants";
import Cookies from "js-cookie";
import Bowser from "bowser";
import { nanoid } from "nanoid";
import { KeyboardEvent } from "react";
import { regexPatterns } from "lib/utils/validations";
import { CheckoutViewType, CouponReplacementInvalidityReasonType } from "lib/types/checkout";
import clarity from "lib/third-party/clarity";
import React from "react";
import { AddressCardFields, ErrorType } from "lib/types/address";
import { getFieldProperties } from "lib/utils/address";

/**
 * Returns if a Checkbox is checked or not
 *
 * @param  {string} checkboxId       The Checkbox ID whose checked status should be found
 *
 * @return {boolean} true/false      Returns if the Checkbox is checked or not checked
 */
export const isChecked = (checkboxId: string) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const checkBox = <HTMLInputElement>document?.getElementById(checkboxId);
  return Boolean(checkBox?.checked);
};

/**
 * Returns if an Objecy is empty or not
 *
 * @param  {object} obj       The Object to be check
 *
 * @return {boolean} true/false      Returns if the Object is empty or not
 */
export const isEmptyObj = (obj: any) => {
  //Check if the input para type is an object.
  //Null in JS is considered as an object. Hence check for null as well.
  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).length === 0;
  }
  return true;
};

/**
 * Returns if the device type is mobile or not (desktop)
 * @return {boolean} true/false      Returns if the device is mobile or not
 */
export const isMobile = () => {
  let hasTouchScreen = false;
  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = (navigator as any).msMaxTouchPoints > 0;
  } else {
    const mQ = (window as any).matchMedia && matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true;
    } else {
      var UA = (navigator as any).userAgent;
      hasTouchScreen = regexPatterns.hasTouchScreen.test(UA);
    }
  }
  if (hasTouchScreen) {
    return true;
  }
  return false;
};

/**
 * Returns the device OS
 * @return {string} WINDOWS/ANDRIOD/IOS/UNKNOWN      Returns if the device is mobile or not
 */
export const detectDeviceOS = () => {
  var userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return "iOS";
  }
  return "unknown";
};

/**
 * Returns the parent Domain.
 * @return {string} domain     Returns the parent domain
 */
export function getParentDomain() {
  const parentUrl = document.referrer;
  const arr = parentUrl?.split("/");
  const domain = arr[2];
  return domain;
}

/**
 * Returns the parent url.
 * @return {string} url     Returns the parent url
 */
export function getParentUrl() {
  const parentUrl = inIframe() ? document.referrer : document.location.href;
  return parentUrl;
}

/**
 * Post Message driver to send data from iFrame to Parent
 *
 * @param  {string} type       The type of message for identification on the script/merchant side. The event types should be same as Checkout-ui-script
 * @param {string} payload     The payload to send to the merchant side
 *
 */
export const publishPostMessage = (type: string, payload: any) => {
  window.parent.postMessage({ type: type, payload: payload, source: "SHOPFLO" }, "*");
  publishWebviewPostMessage(type, payload);
};

/**
 * Publishes a postMessage to the React Native WebView if available.
 * @param type - The type of the message. Should be a type from constants/eventTypes
 * @param payload - The data payload to send.
 */
export const publishWebviewPostMessage = (type: string, payload: any) => {
  try {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ type: type, payload: payload, source: "SHOPFLO" }),
      );
    }
  } catch (e) {
    console.error("Error publishing postMessage:", e);
  }
};

//Closes the Shopflo iFrame and modal. Use this only on Shopflo platform.
export const closeIframe = (isBuyNow: boolean = false) => {
  if (!inIframe()) return;
  publishPostMessage(isBuyNow ? eventTypes.CLOSE_IFRAME_BUYNOW : eventTypes.CLOSE_IFRAME, "");
};

/**
 * Method to format a cookie for document.cookie
 *
 * @param {string} cookieName                The name of the cookie to be stored
 * @param {string} cookieValue                The cookie value to be stored
 * @param {string} cookieExpiry (in minutes)  The cookie cookieExpiry in minutes
 *
 * @return {string} cookie                    Returns the formatted cookie eligible for document.cookie
 */
export const formatCookie = (cookieName: string, cookieValue: string, cookieExpiry: number) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() + cookieExpiry);
  const expires = `expires=${date}`;
  const cookie = `${cookieName}=${cookieValue};${expires};path=/;SameSite=None;Secure`;
  return cookie;
};

/**
 * Function to check if browser allows third party cookies
 */
export const isThirdPartyCookieEnabled = () => {
  Cookies.set("flo-thirdparty-cookie", "thirdparty_cookie", {
    expires: 1 / 24,
    sameSite: "none",
    secure: true,
  });
  const cookie = Cookies.get("flo-thirdparty-cookie");
  return Boolean(cookie);
};

/**
 * Function to check if the website is loaded in an Iframe or not.
 */
export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Function to redirect the checkout to success page based on where it is loaded (iframe/web)
 * @param  {string} redirectUrl    The redirect url
 */
export const handlePaymentSuccessRedirection = (redirectUrl: string, clearCart: boolean = true) => {
  if (Boolean(redirectUrl)) {
    if (inIframe()) {
      publishPostMessage(eventTypes.PARENT_REDIRECT, { redirectUrl: redirectUrl, clearCart: clearCart });
      return;
    }
    if(window.top) {
      window.top.location.href = redirectUrl;
      return
    }
    window.location.href = redirectUrl;
    return;
  }
};

/**
 * Function to change the index of an element to a desired index.
 * @param  {array} arr          The input array
 * @param {number} fromIndex    The index of the current element whose index is to be changed
 * @param {number} toIndex       The new desired index of the element
 */
export const arrayMove = (arr: any, fromIndex: number, toIndex: number) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};

/**
 * Method to remove any special characters from the given string.
 * This removes any character that is not alphanumeric.
 *
 * @param  {string} str               The string whose
 *
 * @return {string} parsed String      Returns a parsed string with the special characters removed including spaces, replaced by "_"
 */
export const replaceCharacter = (str: string) => {
  if (!Boolean(str)) return "";
  str = str.trim();
  return str.replace(regexPatterns.alphaNumeric, "_");
};

/**
 * Method takes deviceInfo returned from bowser.js package and concatenates it into a string.
 * NPM Package: https://www.npmjs.com/package/bowser
 * String Builder : OS-OS_VERSION-OS_VERSION_NAME-BROWSER-BROWSER_VERSION
  * @param {object} deviceInfo = {
            *                      browser: {
                                      name: "Internet Explorer"
                                      version: "11.0"
                                    },
                                    os: {
                                      name: "Windows"
                                      version: "NT 6.3"
                                      versionName: "8.1"
                                    }
                                  }
 *
 * @return {string} concatenated String
 */
export const concateDeviceInfo = (deviceInfo: any) => {
  if (isEmptyObj(deviceInfo)) return "";

  let stringBuilder: string = "";
  if (Boolean(deviceInfo.os?.name)) {
    stringBuilder += replaceCharacter(deviceInfo.os?.name).toUpperCase() + "-";
  }
  if (Boolean(deviceInfo.os?.version)) {
    stringBuilder += replaceCharacter(deviceInfo.os?.version).toUpperCase() + "-";
  }
  if (Boolean(deviceInfo.os?.versionName)) {
    stringBuilder += replaceCharacter(deviceInfo.os?.versionName).toUpperCase() + "-";
  }
  if (Boolean(deviceInfo.browser?.name)) {
    stringBuilder += replaceCharacter(deviceInfo.browser?.name).toUpperCase() + "-";
  }
  if (Boolean(deviceInfo.browser?.version)) {
    stringBuilder += replaceCharacter(deviceInfo.browser?.version).toUpperCase();
  }
  return stringBuilder;
};

/**
 * Function to determine the device information: browser, OS, and platform
 * NPM Package: https://www.npmjs.com/package/bowser
 * @return {object} deviceInfo    Returns complete device information inclduing browser, OS, and platform
 * type deviceInfo = {
 *                      browser: {
                          name: "Internet Explorer"
                          version: "11.0"
                        },
                        os: {
                          name: "Windows"
                          version: "NT 6.3"
                          versionName: "8.1"
                        }
                      }
 */
export const getDeviceInfo = () => {
  const deviceInfo = Bowser.parse(window.navigator.userAgent);
  const concatenatedDeviceInfo: string = concateDeviceInfo(deviceInfo);
  return concatenatedDeviceInfo;
};

/**
 * Method to check if the window is in focus or not
 *
 * @return {boolean} isInFocus     Returns if the window is in focus or not.
 */
export const isWindowInFocus = () => {
  let onchange = function (evt: any) {
    let v = "visible",
      h = "hidden",
      evtMap: any = {
        focus: v,
        focusin: v,
        pageshow: v,
        blur: h,
        focusout: h,
        pagehide: h,
      };
    evt = evt || window.event;
    if (evt.type in evtMap) h === evtMap[evt.type] ? (isInFocus = false) : (isInFocus = true);
    document.removeEventListener(visibilityChange, onchange, false);
  };
  // Standard browsers:
  var hidden = "hidden",
    visibilityChange = "visibilitychange";
  if (typeof document?.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof (document as any)?.mozHidden !== "undefined") {
    hidden = "mozHidden";
    visibilityChange = "mozvisibilitychange";
  } else if (typeof (document as any)?.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof (document as any)?.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  } else if ("onfocusin" in document) (document as any).onfocusin = (document as any).onfocusout = onchange;
  //IOS devices and others
  else window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;
  let isInFocus = false;

  document.addEventListener(visibilityChange, onchange, false);
  if ((document as any)[hidden] !== undefined)
    onchange({ type: (document as any)[hidden] ? "blur" : "focus" });
  return isInFocus;
};

/**
 * Method to check if the window is loaded in Instagram web view or not
 *
 * @return {boolean} isInFocus     Returns if the window is loaded in Instagram web view or not
 */
export const isInstagramBrowser = () => {
  let ua = navigator.userAgent;
  return ua.indexOf("Instagram") > -1 ? true : false;
};

/**
 * Method to get value of a given query parameter from the current URL
 * @param {string} queryParam      The query parameter whose value is to be retrieved
 *
 * @return {string}                Returns the value of the query parameter request. If the parameter is not present, method returns empty string
 */
export const getQueryParamByName = (queryParam: string) => {
  const url: string = window.location.href;
  queryParam = queryParam.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + queryParam + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * Method to convert and capitalize the first letter of a string
 * @param {string} inputParam The string whose first letter is to be capitalized
 *
 * @return {string} Returns the converted string with the first letter capital. If the input string is empty, then empty string is returned
 */

export const capitalizeFirstCharacter = (inputParam: string) => {
  return inputParam
    ? `${inputParam.substring(0, 1)?.toUpperCase()}${inputParam
        .substring(1, inputParam.length)
        ?.toLowerCase()}`
    : inputParam;
};

/**
 * Method to generate 21bits random UUID. Uses nanoId library under the hood whose total possilites are 21^64 = 4e^84.
 * Library added 87Bytes of extra space
 * https://www.npmjs.com/package/nanoid
 * @return {string}       Returns a random UUID
 */
export const generateUUID = (): string => {
  return nanoid();
};

/**
 * Method to generate and create a session ID in the cookie that is valid for 15 minutes. This cookie will be sent with every XHR request.
 */
export const createFloSessionCookie = () => {
  const floSessionCookie = Cookies.get(constants.FLO_SESSION_ID_COOKIE);
  if (floSessionCookie) return;
  const floSessionId: string = generateUUID();
  const floSessionCookieExpiry = new Date(new Date().getTime() + 15 * 60 * 1000); //15 minutes
  Cookies.set(constants.FLO_SESSION_ID_COOKIE, floSessionId, {
    expires: floSessionCookieExpiry,
    sameSite: "none",
    secure: true,
  });
};

/**
 * Method to listen when key pressed and to trigger the callback function
 */
export const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>, key: string, callback: () => void) => {
  if (e.code === key) {
    e.preventDefault();
    callback();
  }
};

/**
 * Method to get Hex for color code with aplha applied to it
 * @param {string} color      The primary color set by the merchant.
 *
 * @param {number} aplha      0 to 1 provide the opacity relative to the primary color.
 *
 * @return {string}           Returns the hex color code for color code with aplha applied to it.
 */
export const addAlpha = (color: string, opacity: number) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

type OnLoadCallback = () => void | Promise<any>;
export const loadScript = (src: string, onLoadCallback: OnLoadCallback) =>
  new Promise((resolve, reject) => {
    const tag = document.createElement("script");
    tag.async = false;
    tag.src = src;
    try {
      tag.onload = async () => {
        const response = await onLoadCallback();
        return resolve(response);
      };
    } catch (e) {
      return reject(e);
    }

    const head = document.getElementsByTagName("head")[0];
    head.appendChild(tag);
  });

/**
 * Method to check if a value is present in the object.
 * Note: This method only check one level and does not work for nested key-value pairs
 * @param {object} obj      The object in which the value is to be checked
 *
 * @param {string} value    The value which is to be checked
 *
 * @return {boolean}        Returns true if the value is present in the object, else false.
 */
export const hasValueInObject = (obj: {}, value: string): boolean => {
  if (isEmptyObj(obj)) return false;
  if (Object.values(obj).indexOf(value) > -1) {
    return true;
  }
  return false;
};
/**
 * Method to add delay consecutive syncronus calls
 *
 * @param {number} ms      Milli seconds upto which the upcomming function is delayed.
 *
 * @return {Promise}
 */
export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Method to add delay consecutive syncronus calls
 *
 * @param {string} phone      User's phone number.
 *
 * @return {string}            Phone number with spaces.
 */
export const setPhoneNumberSpacing = (phone?: string) => {
  return phone?.replace(/(\d{2})(\d{5})(\d{5})/, "$1 $2 $3");
};

/**
 * Checks for the refresh token. If refresh token doesn't exist that it's a guest user.
 *
 * @return {boolean}            True if its a guest user.
 */
export const isGuestUser = () => {
  const idToken = Cookies.get(constants.REFRESH_TOKEN_COOKIE_CLIENT);
  return !Boolean(idToken) ? true : false;
};

/** Method to insert a string before last occurrence of of a specific token string in string.
 * Get color (black/white) depending on bgColor so it would be clearly seen.
 * @param bgColor
 * @returns {string}
 */
export const getColorByBgColor = (bgColor: string) => {
  if (!bgColor) {
    return "";
  }
  return parseInt(bgColor.replace("#", ""), 16) > 0xffffffff / 1.2 ? "#1a1a1a" : "#fff";
};

/**
 * Method to insert a string before last occurrence of of a specific token string in string.
 * @param {string} strToSearch      The string to search from.
 *
 * @param {string} strToFind        The token string to be searched.
 *
 * @param {string} strToInsert      The string to added before the token.
 *
 * @return {string}           Returns the modified string after adding the insert string.
 */
export const insertBeforeLastOccurrence = (strToSearch: string, strToFind: string, strToInsert: string) => {
  var n = strToSearch?.lastIndexOf(strToFind);
  if (n < 0) return strToSearch;
  return strToSearch?.substring(0, n) + strToInsert + strToSearch?.substring(n);
};

/** Method checks for country code in phone number.
 * @param {string} phone                  Phone number to be checked.
 * @returns {string}                      Phone number with default country code added +91
 *                                        if there was any country code present in phone number
 */
export const addDefaultCountryCode = (phone: string) => {
  if (phone[0] !== "+") {
    return `+91${phone}`;
  }
  return phone;
};

/** Method returns the primary dark color of the theme
 * @returns {string}                      returns the primary-dark color of the theme
 */
export const getPrimaryDarkColor = () => {
  return getComputedStyle(document.documentElement).getPropertyValue("--flo-primary-dark-color");
};

/** Method to redirect any URL based on it is loaded on iFrame or not.
 * @param {string} redirectUrl                     The complete URL which needs to be redirected
 */
export const redirectUrl = (redirectUrl: string) => {
  if (!Boolean(redirectUrl)) return;

  if (inIframe()) {
    try {
      (window as any).top.location.href = redirectUrl;
    } catch (e) {
      publishPostMessage(eventTypes.PARENT_REDIRECT, { redirectUrl: redirectUrl });
    }
    return;
  }
  window.location.href = redirectUrl;
};

/** Method to check if modal/popup/dialog is open in the view port
 * @param {string} modalId
 *
 * @return {boolea} returns true if modal is open, else false
 */
export const isModalOpen = (modalId: string = "headlessui-portal-root") => {
  try {
    if (!Boolean(modalId)) return false;
    const modalEl = document.getElementById(modalId);
    return Boolean(modalEl);
  } catch (e) {
    console.error(e);
    return false;
  }
};

/** Method to mask a given string
 * @param {string} originalString The string to be masked
 *
 * @param {number} maskedCharacters Number of characters to be masked
 *
 * @param {number} visibleCharacters Number of characters that are visible
 *
 * @return {string} returns masked string
 */
export const maskString = (
  originalString: string,
  totalLength: number,
  visibleCharacters: number,
  maskingCharacter: string,
) => {
  try {
    if (visibleCharacters > originalString.length) {
      return originalString;
    }
    return originalString
      .slice(originalString.length - visibleCharacters)
      .padStart(totalLength, maskingCharacter);
  } catch (e) {
    console.error(e);
    return originalString;
  }
};

/**
 * Returns if an Objecy is empty or not
 *
 * @param  {object} object1       First object to be compared
 *
 * @param  {object} object2       Second object to be compared
 *
 * @return {boolean} true/false      Returns if the objects are equal or not
 */
export const areObjectsEqual = (object1: any, object2: any): boolean => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  function isObject(object: any) {
    return object != null && typeof object === "object";
  }

  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !areObjectsEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
};

export const utmParamKeys = [
  "utm_medium",
  "utm_source",
  "utm_campaign",
  "utm_content",
  "utm_term",
  "gclid",
  "fbclid",
];

export const getQueryParams = (url: string) => {
  try {
    if (!url) {
      return [];
    }
    url = url.replace(/%([^\d].)/, "%25$1");
    url = decodeURIComponent(url);
    let question = url.indexOf("?");
    let hash = url.indexOf("#");
    if (hash === -1 && question === -1) return [];

    if (hash === -1) hash = url.length;
    let query =
      question === -1 || hash === question + 1 ? url.substring(hash) : url.substring(question + 1, hash);
    let result: any = [];
    query.split("&").forEach(function (part) {
      if (!part) return;
      let eq = part.indexOf("=");
      let param: any;
      //Case where there is no key/value pair, last key/value contains & in between e.g. &a&b=c
      if (eq === -1 && result.length > 0) {
        if (utmParamKeys.includes(part)) {
          param = {
            name: part,
            value: "",
          };
          result.push(param);
          return;
        }
        let lastVal = result[result.length - 1]["value"];
        result[result.length - 1]["value"] = lastVal + "&" + part;
        return;
      }
      let key = eq > -1 ? part.substr(0, eq) : part;
      let val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)?.replace(/%([^\d].)/, "%25$1")) : "NA";
      let from = key.indexOf("[");

      if (from === -1) {
        param = {
          name: decodeURIComponent(key),
          value: val,
        };
      } else {
        let to = key.indexOf("]", from);
        let index = decodeURIComponent(key.substring(from + 1, to));
        key = decodeURIComponent(key.substring(0, from));
        if (!param?.[key]) {
          param["name"] = key;
          param["value"] = [];
        }
        if (!index) param["value"].push(val);
        else param["value"][index] = val;
      }

      result.push(param);
    });
    return result;
  } catch (err) {
    console.error(err);
    return [];
  }
};

/**
 * Function to get the primary dark color with alpha/opacity.
 * @param {number} opacity - The opacity value ranging from 0 to 1.
 * @returns {string} - The primary dark color with alpha applied.
 */
export const getPrimaryDarkColorWithAlpha = (opacity: number): string => {
  const primaryDarkColor: string = getPrimaryDarkColor();
  if (primaryDarkColor?.length === 9) return primaryDarkColor;
  const colorWithAlpha: string = addAlpha(primaryDarkColor, opacity);
  return colorWithAlpha;
};

/**
 * Converts a hexadecimal color code to an RGBA color with a specified alpha value.
 *
 * @param hex - The hexadecimal color code (e.g., "#01CCA7").
 * @param alpha - The alpha (opacity) value to apply to the RGBA color (between 0 and 1).
 * @returns The RGBA color in the format "rgba(r, g, b, alpha)".
 * @throws If the input hex color is invalid.
 */
export function hexToRgba(hex: string, alpha: number): string {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let hexValues = hex.substring(1).split("");
    if (hexValues.length === 3) {
      hexValues = [hexValues[0], hexValues[0], hexValues[1], hexValues[1], hexValues[2], hexValues[2]];
    }
    const rgba = `rgba(${[
      parseInt(hexValues.slice(0, 2).join(""), 16),
      parseInt(hexValues.slice(2, 4).join(""), 16),
      parseInt(hexValues.slice(4, 6).join(""), 16),
    ].join(",")},${alpha})`;
    return rgba;
  } else {
    throw new Error(`Invalid Hexadecimal Color: ${hex}`);
  }
}

/**
 * Extracts the off amount or percentage string from a given input string.
 *
 * @param concession - The input string containing concession amount/percentage.
 * @returns The off amount or percentage string, or `null` if no match is found.
 */
export function extractPrepaidDiscountOffString(
  concession: number,
  couponType: "PERCENTAGE" | "FLAT",
): string | null {
  if (!concession || !couponType) return null;
  if (couponType === "PERCENTAGE") return `${concession}% off`;
  if (couponType === "FLAT") return `${concession}₹ off`;
  return null;
}
export const exitCheckout = (redirectUrl: string, isBuyNow: boolean = false) => {
  publishPostMessage(eventTypes.EXIT_CHECKOUT, {});
  if (inIframe()) {
    closeIframe(isBuyNow);
    return;
  }
  if (Boolean(redirectUrl)) {
    window.location.href = redirectUrl;
    return;
  }
};

export const getUTMParamsFromURL = () => {
  let utmParams = [];
  try {
    const queryParams = window.location.search;
    if (
      utmParamKeys?.some(function (param) {
        return queryParams.indexOf(param) >= 0;
      })
    ) {
      utmParams = getQueryParams(queryParams);
      utmParams = utmParams?.filter((param: any) => utmParamKeys.includes(param.name));
    }
  } catch (e) {
    console.error(e);
  } finally {
    return utmParams;
  }
};

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

/** Method for listening to scroll activity happening in an element
 *
 * @param {HTMLElement} element The element for scroll tracking
 *
 * @param {number} scrollProgressCounter The value of scroll counter to be set
 *
 * @param {React.Dispatch<React.SetStateAction<number>>} setScrollProgress State Dispatch method to set state
 *
 */
export const scrollListener = (
  element: HTMLElement,
  scrollProgressCounter: number,
  setScrollProgress: React.Dispatch<React.SetStateAction<number>>,
) => {
  const windowScroll = element.scrollLeft;
  const totalWidth = element.scrollWidth - element.clientWidth;

  if (windowScroll === 0) setScrollProgress(0);
  if (windowScroll > totalWidth) setScrollProgress(scrollProgressCounter);
  return setScrollProgress((windowScroll / totalWidth) * 100);
};

/** Method to return number from a string starting with number
 *
 * @param {string} str The string to extract number from
 *
 * @returns {number} The extracted number
 */
export const getStartNumberFromString = (str: string) => {
  const numString = str.match(/\d+/)?.[0];
  return Number(numString);
};

/** Method to return number from a string starting with number
 *
 * @param {string} str        The string to be truncated
 *
 * @param {number} maxLength  The length to be truncated from
 *
 * @returns {number} The extracted number
 */
export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.substring(0, maxLength) + "...";
  }
};

/**
 * Checks if a URL string starts with 'http://' or 'https://'. If not, it adds 'http://' at the beginning.
 *
 * @param {string} url - The input URL string.
 *
 * @returns {string} - The modified URL string with 'http://' or 'https://' added if missing.
 */
export const addHttpIfMissing = (url: string): string => {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else {
    return "http://" + url;
  }
};

export const isBraveBrowser = async (): Promise<boolean> =>
  // @ts-ignore
  (navigator.brave && (await navigator.brave.isBrave())) || false;

/**
 * Method to floor numbers to two decimal points.
 *
 * @param {number} number      Number to be floored.
 *
 * @return {number}
 */
export const floorToTwoDecimals = (number: number) => {
  return Math.floor(number * 100) / 100;
};

export const checkMandatoryFields = (selectedAddress: AddressCardFields, addressConfig: any[]) => {
  const countryCode = selectedAddress?.country_code;
  let errorFieldData: Record<string, ErrorType | undefined> = {};
  if (countryCode !== "IN") {
    const phoneNumber = selectedAddress?.phone;
    const emailAddress = selectedAddress?.email;
    const name = selectedAddress?.name;
    return {
      available: Boolean(phoneNumber) && Boolean(emailAddress) && Boolean(name),
      errorFieldData: errorFieldData,
    };
  }

  if (
    selectedAddress?.country_code === "IN" &&
    selectedAddress?.phone !== "" &&
    selectedAddress?.phone?.substring(0, 3) !== "+91"
  ) {
    return {
      available: Boolean(selectedAddress?.alternate_number && selectedAddress?.alternate_number?.length > 10),
      errorFieldData: errorFieldData,
    };
  }

  const containsAllMandatoryFields = addressConfig.every((field: any) => {
    if (Boolean(field.isEnabled) && Boolean(field.isMandatory)) {
      if (field.key in (selectedAddress ?? {})) return true;
      const fieldData: any = getFieldProperties(field.key, field.isMandatory);
      errorFieldData[field.key] = { status: true, message: fieldData?.required };
      return false;
    }
    return true;
  });
  return { available: containsAllMandatoryFields, errorFieldData: errorFieldData };
};

/** Method to return number from a string starting with number
 *
 * @param {string} id ID of component to scroll to
 *
 */
export const scrollToID = (id: string) => {
  const section = document.querySelector(`#${id}`);
  if (section) {
    section.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};

/**
 * Rounds a string number based on its decimal value.
 * If the decimal value is less than 0.50, rounds down.
 * Otherwise, rounds up.
 *
 * @param {string} input - The string representation of the number to be rounded.
 * @returns {number} - The rounded number as a string.
 */
export const roundStringNumber = (input: string | number): number => {
  // Convert the input string to a float number
  const number = typeof input === "string" ? parseFloat(input) : input;

  // Round the number based on its decimal part
  const roundedNumber = Math.round(number);

  // Convert the rounded number back to a string
  return roundedNumber;
};

export const getInvalidityReason = (reasonsList: string[]): CouponReplacementInvalidityReasonType => {
  if (!Boolean(reasonsList.length)) return "NONE";

  if (reasonsList?.some((reason: string) => reason === "DISCOUNTS_NOT_COMBINABLE"))
    return "DISCOUNTS_NOT_COMBINABLE";
  if (reasonsList?.some((reason: string) => reason === "NO_MORE_STACK_LENGTH")) return "NO_MORE_STACK_LENGTH";

  return "NONE";
};

/** Method to set custom page clarity tags
 *
 * @param {string} checkoutView The current view of checkout
 *
 */
export const setPageClarityTags = (checkoutView: CheckoutViewType) => {
  if (!Boolean(clarity.hasStarted())) return;
  switch (checkoutView) {
    case "ADDRESS_LIST":
      clarity.setTag(clartityTags.PAGE_VISITED, "ADDRESS");
      return;
    case "AUTH":
      clarity.setTag(clartityTags.PAGE_VISITED, "LOGIN");
      return;
    case "PAYMENTS":
      clarity.setTag(clartityTags.PAGE_VISITED, "PAYMENTS");
      return;
    default:
      return;
  }
};

/** Method to set custom clarity tags
 *
 * @param {string} key The key for the tag to be set
 *
 * @param {any} value The value for the tag to be set
 *
 */
export const setClarityTag = (key: string, value: any) => {
  if (!Boolean(clarity.hasStarted()) || !Boolean(key) || !Boolean(value)) return;
  clarity.setTag(key, value);
};

export const getRegionFromTimezone = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase();
  if (timezone.includes("america")) {
    return "us";
  }
  if (timezone.includes("europe")) {
    return "eu";
  }
  return "ap";
};
